<template>
  <div class="allPage">
    <heads :more="showMore" :msg="title_msg"></heads>
    <van-list
      v-if="isshow"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div v-for="(thread,index) in lists" :key="index" @click="go_url(thread.detail_url)">
        <datalists
          :type="1"
          :listType="2"
          :thumb="thread.pic"
          :atitle="thread.thread_title"
          :createTime="thread.create_time"
          :vNum="thread.view_num"
          :cNum="thread.reply_num"
        ></datalists>
      </div>
    </van-list>
    <div v-else class="kongzt">
      <img src="../../static/images/my_index/kongzt.png" alt />
      <p>您还没有回复过哦~</p>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import commontop from '@/compontens/menu.vue'
import tool from '@/utils/tools'
import datalists from '@/compontens/datalist'

import { get_hf_list } from '@/api/my_user.js'
export default {
  components: {
    heads,
    commontop,
    datalists
  },
  data () {
    return {
      title_msg: '我的回复',
      limit: 10,
      page: 1,
      lists: [],
      isshow: true,
      showMore: true,
      loading: false,
      finished: false,
    }
  },
  beforeMount () {
    if (tool.isInApp()) {
      if (tool.isInIosApp()) {
        window.onpageshow = function (e) {
          if (e.persisted) {
            window.location.reload()
          }
        }
      }
    }
  },
  methods: {
    formatDate (date) {
      var date = new Date(date * 1000)
      return tool.formatDate(date, 'yyyy-MM-dd')
    },
    go_url (data) {
      window.location.href = data
    },
    onLoad () {
      this.get_lists()
    },
    get_lists () {
      this.loading = true
      var params = {
        page: this.page,
        limit: this.limit
      }
      get_hf_list(params).then(res => {
        this.loading = false
        const result = res.result ? res.result : {}
        // 总数
        const count = result ? result.count : 0
        const list = result ? result.list : []
        this.lists = this.lists.concat(list)
        console.log(this.lists.length)
        if (this.lists.length > 0) {
          this.isshow = true
        }
        else {
          this.isshow = false
        }
        if (this.lists.length < count) {
          this.page++
          console.log('还有的啊 继续加加加啊 ' + this.page)
        }
        else {
          // 没有更多了
          this.finished = true
        }
      })
    }
  },
  mounted () {
    this.get_lists()
  },
}
</script>


<style lang="scss" scoped>
.kongzt {
  width: 100%;
  img {
    width: 310px;
    height: 310px;
    margin: 0 auto;
    display: block;
    margin-top: 300px;
  }
  p {
    font-size: 30px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding-top: 30px;
  }
}
// .thread {
//   width: 100%;
//   height: 280px;
//   display: flex;
//   justify-content: flex-end;
//   border-bottom: #eee 1px solid;
// }
// .content {
//   // width: calc(100% - 280px);
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   //自动计算剩余宽度
//   flex: 1;
//   .thread_title {
//     margin: 30px 20px 30px 30px;
//     font-size: 30px;
//     color: #333333;
//     line-height: 50px;
//     word-break: break-all;
//     word-wrap: break-word;
//     overflow: hidden;
//     display: -webkit-box;
//     -webkit-line-clamp: 3;
//     -webkit-box-orient: vertical;
//   }
//   .bot_info {
//     height: 50px;
//     margin-bottom: 20px;
//     line-height: 50px;
//     .eye_img {
//       margin-left: 30px;
//       width: 28px;
//       height: 18px;
//     }
//     .people_bro {
//       margin-left: 8px;
//       color: #999999;
//       display: inline-block;
//     }
//     .kuang_img {
//       margin-left: 30px;
//       width: 24px;
//       height: 18px;
//     }
//     .people_gen {
//       margin-left: 8px;
//       color: #999999;
//       display: inline-block;
//     }
//   }
// }
// .right_box {
//   width: 280px;
//   height: 250px;
//   margin-top: 30px;
//   margin-right: 30px;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   .thumb {
//     width: 100%;
//     height: 158px;
//   }
//   .bot_time {
//     height: 50px;
//     margin-bottom: 20px;
//     text-align: right;
//     line-height: 50px;
//     color: #999999;
//   }
// }
// .img_h {
//   width: 40px;
//   height: 40px;
//   position: absolute;
//   right: 30px;
//   top: 44px;
//   z-index: 1;
// }
// .eye_img {
//   width: 28px;
//   height: 18px;
//   position: absolute;
//   bottom: 44px;
//   left: 30px;
// }
// .kuang_img {
//   width: 24px;
//   height: 18px;
//   position: absolute;
//   bottom: 44px;
//   left: 222px;
// }

// h4 {
//   font-size: 30px;
//   word-wrap: break-word;
//   word-break: normal;
//   width: 353px;
//   letter-spacing: 3px;
//   padding: 37px 0 0 32px;
//   line-height: 56px;
// }
// .people_bro {
//   padding-left: 66px;
//   float: left;
//   color: #9b9b9b;
// }
// .people_gen {
//   float: left;
//   padding-left: 69px;
//   color: #9b9b9b;
// }
// .bro_time {
//   float: right;
//   padding: 4px 33px 0 0;
//   color: #9b9b9b;
// }
// .main {
//   width: 100%;
//   height: 276px;
//   border-bottom: #efefef 1px solid;
//   overflow: hidden;
//   position: relative;
// }
// .ll_img {
//   width: 279px;
//   height: 157px;
//   margin-top: 32px;
// }
// .main_top_left {
//   float: left;
//   width: 440px;
//   height: 205px;
// }
// .main_top_right {
//   float: left;
//   overflow: hidden;
//   height: 205px;
// }
.allPage {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #f5f5f5;
}
</style>
